import React from 'react';

const UserGuideHeader = function UserGuideHeader() {
  return (
    <div className="card-header">
      <h1>Perfherder User Guide</h1>
    </div>
  );
};

export default UserGuideHeader;
